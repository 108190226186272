import { Box, Flex, HStack, Select } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Alert, SearchInput } from "../../../components";
import {
  useNumberSearchParam,
  useSearchParam,
} from "../../../hooks/useSearchParam";
import { getAllowedLimitValues } from "../../../utils/pagination";
import CallAiSummaryTemplateList from "../../components/CallAiSummaryTemplateList/CallAiSummaryTemplateList";
import { useOrgCallAiSummaryTemplatesQuery } from "../../graphql";

const DEFAULT_LIMIT = 50;

const TemplatesList: React.FC<{
  category: "my" | "shared" | "all";
  active: boolean;
}> = ({ category, active }) => {
  const location = useLocation();
  const [query, setQuery] = useSearchParam("q");
  const [limit, setLimit] = useNumberSearchParam("limit", DEFAULT_LIMIT);
  const limits = useMemo((): number[] => {
    return getAllowedLimitValues(limit ?? DEFAULT_LIMIT);
  }, [limit, location.pathname]);

  const { loading, error, data } = useOrgCallAiSummaryTemplatesQuery();
  const templates =
    data?.currentUser?.organization.callAiSummaryTemplates || [];

  const search = (
    <Flex py={4} flexFlow="row nowrap" width="100%">
      <SearchInput
        height="40px"
        pr={4}
        maxWidth="450px"
        defaultValue={query}
        placeholder="Search templates"
        onSearch={(updatedQuery: string) => {
          if (updatedQuery !== query) {
            setQuery(updatedQuery);
          }
        }}
        isDisabled // TODO: enable when pagination is implemented
      />
      <Flex alignItems="center">
        <HStack hidden={false}>
          <Box px={2} whiteSpace="nowrap">
            Max results
          </Box>
          <Select
            defaultValue={limit ?? DEFAULT_LIMIT}
            size="sm"
            bg="white"
            width="120px"
            onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
              setLimit(parseInt(e.currentTarget.value, 10));
            }}
            isDisabled // TODO: enable when pagination is implemented
          >
            {limits.map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>
    </Flex>
  );

  if (error)
    return (
      <>
        {search}
        <Alert
          status="error"
          title="Error loading templates"
          description={error.message}
        />
      </>
    );

  return (
    <>
      {/* <EditTemplateModal /> */}
      <Box mt="4">{search}</Box>

      <CallAiSummaryTemplateList
        templates={templates}
        loading={loading}
        sortBy={{ id: "createdAt", desc: true }}
      />
    </>
  );
};

export default TemplatesList;
