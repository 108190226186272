import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  RequireUUIDParams as RequireUUIDParamsBase,
  RequireUUIDParamsProps,
} from "../components";
import { RequireAuth } from "./components";
import FeatureAccess from "./components/FeatureAccess/FeatureAccess";
import FeatureGate from "./components/FeatureGate";
import { FeatureName } from "./graphql";
import AiRecruiter from "./pages/ai_recruiter/AiRecruiter";
import AnalyticsMetrics from "./pages/analytics/AnalyticsMetrics";
import AnalyticsMetricsTopics from "./pages/analytics/AnalyticsMetricsTopics";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import AnalyticsReport from "./pages/analytics/AnalyticsReport";
import AnalyticsCandidateMotivations from "./pages/analytics/candidate-motivations/AnalyticsCandidateMotivations";
import AnalyticsCandidateQuestions from "./pages/analytics/candidate-questions/AnalyticsCandidateQuestions";
import AnalyticsMyAnalytics from "./pages/analytics/my-analytics/AnalyticsMyAnalytics";
import AnalyticsOverviewVersionSwitch from "./pages/analytics/overview/AnalyticsOverviewVersionSwitch";
import AnalyticsSkillsReport from "./pages/analytics/skills-report/AnalyticsSkillsReport";
import AnalyticsSkillsReports from "./pages/analytics/skills-report/AnalyticsSkillsReports";
import AnalyticsSkillsReportBuilder from "./pages/analytics/skills-report/builder/AnalyticsSkillsReportBuilder";
import AnalyticsTopicTrends from "./pages/analytics/topic-trends/AnalyticsTopicTrends";
import { SelectScorecardAutoDraftPage } from "./pages/auto-draft-scorecard";
import CallPage from "./pages/call/CallPage";
import CancelRecordingPage from "./pages/cancel-recording/CancelRecordingPage";
import CandidatePage from "./pages/candidate/CandidatePage";
import CandidateEmailOptOutPage from "./pages/candidate-opt-out/CandidateEmailOptOutPage";
import VerifyEmailPage from "./pages/candidate-opt-out/VerifyEmailPage";
import CandidateSummaryEditPage from "./pages/candidate-summary/CandidateSummaryEditPage";
import CandidatesPage from "./pages/candidates/Candidates";
import ClipPage from "./pages/clip/ClipPage";
import MyClipsPage from "./pages/clip/MyClips";
import ExtensionLandingPage from "./pages/extension/ExtensionLandingPage";
import ExternalSharePage from "./pages/external-share/ExternalSharePage";
import Forbidden from "./pages/forbidden/Forbidden";
import GuidePage from "./pages/guide/GuidePage";
import GuidesPage from "./pages/guides/GuidesPage";
import HomePage from "./pages/home/HomePage";
import InterviewOptInPage from "./pages/interview-opt-in/InterviewOptInPage";
import InterviewerOptOutPage from "./pages/interviewer-opt-out/InterviewerOptOutPage";
import NoAccessPage from "./pages/no-access/NoAccessPage";
import NotFound from "./pages/not-found/NotFoundPage";
import MyPlaylistsPage from "./pages/playlists/MyPlaylistsPage";
import PositionPage from "./pages/position/PositionPage";
import PositionsPage from "./pages/positions/PositionsPage";
import RegisterInfoPage from "./pages/register-info/RegisterInfoPage";
import VerifyPhonePage from "./pages/register-phone/RegisterPhonePage";
import SearchPage from "./pages/search/SearchPage";
import SelectPersonaPage from "./pages/select-persona/SelectPersonaPage";
import DataRedactionLog from "./pages/settings/ComplianceSettings/DataRedactionLog";
import SettingsPage from "./pages/settings/SettingsPage";
import SignInPage from "./pages/sign-in/SignInPage";
import SignUpPage from "./pages/sign-up/SignUpPage";
import SupportPage from "./pages/support/SupportPage";
import SyncCalendarPage from "./pages/sync-calendar/SyncCalendarPage";
import TemplatesPage from "./pages/templates/TemplatesPage";
import AllTraineesPage from "./pages/training/AllTraineesPage";
import ManageTrainingPage from "./pages/training/ManageTrainingPage";
import ManageTrainingRedirectPage from "./pages/training/ManageTrainingRedirectPage";
import ViewTrainingPage from "./pages/training/ViewTrainingPage";
import ViewTrainingRedirectPage from "./pages/training/ViewTrainingRedirectPage";
import UpgradePage from "./pages/upgrade/UpgradePage";

const RequireUUIDParams: React.FC<Omit<RequireUUIDParamsProps, "element">> = (
  props
) => <RequireUUIDParamsBase element={<NotFound />} {...props} />;

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      {["/", "/schedule"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          }
        />
      ))}
      {[
        `/interview/:callId`,
        `/interview/:callId/add-to-training-program`,
        `/interview/:callId/share`,
        `/interview/:callId/edit`,
        `/interview/:callId/set-language`,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <RequireUUIDParams paramNames={["callId"]}>
                <CallPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      {[
        `/interview/:callId/clip/:clipId`,
        `/interview/:callId/clip/:clipId/share`,
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth hideIntercom>
              <RequireUUIDParams paramNames={["callId", "clipId"]}>
                <ClipPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/clips"
        element={
          <RequireAuth>
            <MyClipsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/playlists"
        element={
          <RequireAuth>
            <MyPlaylistsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/playlist/:playlistId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["playlistId"]}>
              <MyPlaylistsPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      {["/guides", "/guides/shared", "/guides/new"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <GuidesPage />
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="guides/assigned"
        element={
          <RequireAuth>
            <GuidesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/guide/:callGuideId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["callGuideId"]}>
              <GuidePage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      {["/positions", "/positions/mine", "/positions/new"].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <PositionsPage />
            </RequireAuth>
          }
        />
      ))}
      {[
        "/position/:positionId",
        "/position/:positionId/candidates",
        "/position/:positionId/guides",
        "/position/:positionId/faqs",
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <RequireUUIDParams paramNames={["positionId"]}>
                <PositionPage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/candidates"
        element={
          <RequireAuth>
            <CandidatesPage />
          </RequireAuth>
        }
      />
      {[
        "/candidate/:candidateId",
        "/candidate/:candidateId/:positionId",
        "/candidate/:candidateId/position/none",
        "/candidate/:candidateId/position/:positionId",
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <RequireUUIDParams paramNames={["candidateId", "positionId"]}>
                <CandidatePage />
              </RequireUUIDParams>
            </RequireAuth>
          }
        />
      ))}
      <Route
        path="/templates"
        element={
          <RequireAuth>
            <FeatureGate flag="custom_templates_v2:manage">
              <TemplatesPage />
            </FeatureGate>
          </RequireAuth>
        }
      />
      <Route
        path="/debrief/:templateId/edit"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["templateId"]}>
              <CandidateSummaryEditPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      <Route
        path="/training/manage"
        element={
          <RequireAuth>
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/training/upgrade"
            >
              <ManageTrainingRedirectPage />
            </FeatureAccess>
          </RequireAuth>
        }
      />
      <Route
        path="/training/manage/all-trainees"
        element={
          <RequireAuth>
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/training/upgrade"
            >
              <AllTraineesPage />
            </FeatureAccess>
          </RequireAuth>
        }
      />
      <Route
        path="/training/view"
        element={
          <RequireAuth>
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/training/upgrade"
            >
              <ViewTrainingRedirectPage />
            </FeatureAccess>
          </RequireAuth>
        }
      />
      <Route
        path="/training/manage/:trainingProgramId"
        element={
          <RequireAuth>
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/training/upgrade"
            >
              <RequireUUIDParams paramNames={["trainingProgramId"]}>
                <ManageTrainingPage />
              </RequireUUIDParams>
            </FeatureAccess>
          </RequireAuth>
        }
      />
      <Route
        path="/training/view/:trainingProgramId"
        element={
          <RequireAuth>
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/training/upgrade"
            >
              <RequireUUIDParams paramNames={["trainingProgramId"]}>
                <ViewTrainingPage />
              </RequireUUIDParams>
            </FeatureAccess>
          </RequireAuth>
        }
      />
      <Route
        path="/training/upgrade"
        element={
          <RequireAuth>
            <UpgradePage
              headerText="Enhance your interview skills today"
              bodyText="Create personalized training programs with best-practice interview clips, empowering your teams to learn from real-world examples and conduct consistent, high-quality interviews every time."
              buttonText="Upgrade now"
              upgradeImageSrc="/static/images/interview-upgrade.png"
            />
          </RequireAuth>
        }
      />
      <Route path="/analytics/:metric" element={<InsightsPageRedirect />} />
      <Route path="/analytics" element={<InsightsPageRedirect />} />
      <Route
        path="/insights/upgrade"
        element={
          <RequireAuth>
            <UpgradePage
              headerText="Optimize hiring with BrightHire Insights"
              bodyText="Unlock actionable insights from every interview, allowing you to identify patterns, enhance decision-making, and continuously refine your hiring process for optimal results. Effortlessly measure interview quality with a tailored score, customized to fit the unique needs of your organization."
              buttonText="Upgrade now"
              upgradeImageSrc="/static/images/insights-upgrade.png"
            />
          </RequireAuth>
        }
      />
      <Route
        path="/insights"
        element={
          <RequireAuth>
            <AnalyticsPage />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="overview" replace />} />
        <Route
          path="overview"
          element={
            <FeatureAccess
              feature={FeatureName.Training}
              redirectRoute="/insights/upgrade"
            >
              <AnalyticsOverviewVersionSwitch />
            </FeatureAccess>
          }
        />
        <Route
          path="report"
          element={
            <FeatureGate flag="analytics:quality-report">
              <AnalyticsReport />
            </FeatureGate>
          }
        />
        <Route
          path="interviewer/:interviewerId"
          element={
            <FeatureGate flag="analytics:my-insights">
              <RequireUUIDParams paramNames={["interviewerId"]}>
                <AnalyticsMyAnalytics />
              </RequireUUIDParams>
            </FeatureGate>
          }
        />
        <Route
          path="topic-trends"
          element={
            <FeatureGate flag="analytics:topic-trends">
              <AnalyticsTopicTrends />
            </FeatureGate>
          }
        />
        <Route
          path="candidate-motivations"
          element={
            <FeatureGate flag="analytics:candidate-motivations">
              <AnalyticsCandidateMotivations />
            </FeatureGate>
          }
        />
        <Route
          path="candidate-questions"
          element={
            <FeatureGate flag="analytics:candidate-questions">
              <AnalyticsCandidateQuestions />
            </FeatureGate>
          }
        />
        <Route
          path="skill-report"
          element={
            <FeatureGate flag="analytics:skills-report">
              <AnalyticsSkillsReportBuilder />
            </FeatureGate>
          }
        />
        <Route
          path="skill-report/all"
          element={
            <FeatureGate flag="analytics:skills-report">
              <AnalyticsSkillsReports />
            </FeatureGate>
          }
        />
        <Route
          path="skill-report/:reportId"
          element={
            <FeatureGate flag="analytics:skills-report">
              <AnalyticsSkillsReport />
            </FeatureGate>
          }
        />
        <Route path="topic_trends" element={<AnalyticsMetricsTopics />} />
        <Route path="question_trends" element={<AnalyticsMetricsTopics />} />
        <Route path=":metric" element={<AnalyticsMetrics />} />
      </Route>
      <Route path="/users/*" element={<UsersPageRedirect />} />
      <Route
        path="/interviewer-opt-out/:interviewerId"
        element={
          <RequireAuth>
            <RequireUUIDParams paramNames={["interviewerId"]}>
              <InterviewerOptOutPage />
            </RequireUUIDParams>
          </RequireAuth>
        }
      />
      <Route path="/candidate-opt-out/:orgId" element={<VerifyEmailPage />} />
      <Route path="/cancel-recording/:slug" element={<CancelRecordingPage />} />
      <Route
        path="/candidate-opt-out/:orgId/:verificationCode"
        element={<CandidateEmailOptOutPage />}
      />
      <Route path="/launch/interview" element={<InterviewOptInPage />} />
      <Route path="/launch/interview/*" element={<InterviewOptInPage />} />
      <Route
        path="/external-share/:shareType/:shareId"
        element={
          <RequireUUIDParams paramNames={["shareId"]}>
            <ExternalSharePage />
          </RequireUUIDParams>
        }
      />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route
        path="/register-info"
        element={
          <RequireAuth>
            <RegisterInfoPage />
          </RequireAuth>
        }
      />
      <Route
        path="/select-persona"
        element={
          <RequireAuth>
            <SelectPersonaPage />
          </RequireAuth>
        }
      />
      <Route
        path="/sync-calendar"
        element={
          <RequireAuth>
            <SyncCalendarPage />
          </RequireAuth>
        }
      />
      <Route
        path="/register-phone"
        element={
          <RequireAuth>
            <VerifyPhonePage />
          </RequireAuth>
        }
      />
      <Route
        path="/greenhouse-auto-draft"
        element={
          <RequireAuth>
            <SelectScorecardAutoDraftPage />
          </RequireAuth>
        }
      />
      <Route
        path="/search"
        element={
          <RequireAuth>
            <SearchPage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/*"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/compliance-check/log"
        element={
          <RequireAuth>
            <DataRedactionLog />
          </RequireAuth>
        }
      />

      <Route
        path="/ai-recruiter/:promptId"
        element={
          <RequireAuth>
            <FeatureGate flag="ai-recruiter:v0">
              <RequireUUIDParams paramNames={["promptId"]}>
                <AiRecruiter />
              </RequireUUIDParams>
            </FeatureGate>
          </RequireAuth>
        }
      />
      <Route
        path="/ai-recruiter"
        element={
          <RequireAuth>
            <FeatureGate flag="ai-recruiter:v0">
              <AiRecruiter />
            </FeatureGate>
          </RequireAuth>
        }
      />
      <Route
        path="/support"
        element={<RequireAuth>{<SupportPage />}</RequireAuth>}
      />
      <Route
        path="/not-found"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
      <Route
        path="/upgrade"
        element={
          <RequireAuth>
            <UpgradePage
              headerText="Dramatically more efficient hiring"
              bodyText="BrightHire Interview streamlines your process with real-time interviewer guidance, AI-powered notes and highlights, actionable insights, and more."
              buttonText="Upgrade to Interview today"
              upgradeImageSrc="/static/images/interview-upgrade.png"
            />
          </RequireAuth>
        }
      />
      <Route path="/extension-installed" element={<ExtensionLandingPage />} />
      <Route
        path="/unauthorized"
        element={
          <RequireAuth>
            <NoAccessPage />
          </RequireAuth>
        }
      />
      <Route
        path="/forbidden"
        element={
          <RequireAuth>
            <Forbidden />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

const UsersPageRedirect = (): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <RequireAuth>
      <Navigate to={`/settings${pathname}`} replace />
    </RequireAuth>
  );
};

const InsightsPageRedirect = (): JSX.Element => {
  const { metric = "" } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <RequireAuth>
      <Navigate
        replace
        to={`/insights/${metric}${
          searchParams ? `?${searchParams as unknown as string}` : ""
        }`}
      />
    </RequireAuth>
  );
};
