import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { Button, PageContent, PageTitle } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultLayout } from "../../layouts";
import TemplatesList from "./TemplatesList";

const StyledTab: typeof Tab = (props) => <Tab pb="2.5" mb="0" {...props} />;

// NOTE: Look at GuidesPage for consistency
const TemplatesPage: React.FC = () => {
  usePageTracker("templates");
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <PageContent>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle mb="20px" titleText="AI Notes Templates" />
          <Button
            bg="blue.500"
            ml={4}
            size="sm"
            leftIcon={<MdAdd />}
            onClick={() => {
              navigate("/templates/new");
            }}
            isDisabled // TODO: enable when create is implemented
          >
            New template
          </Button>
        </Flex>
        <Tabs isLazy index={0}>
          <TabList borderBottom="1px solid" borderColor="gray.200">
            <StyledTab>All Templates</StyledTab>
          </TabList>

          <TabPanels>
            <TabPanel data-testid="my-call-ai-summary-templates-panel">
              <TemplatesList category="all" active />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageContent>
    </DefaultLayout>
  );
};

export default TemplatesPage;
